import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useFormik } from 'formik';
import { kebabCase } from 'lodash';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import VideoCard from '../components/VideoCard';
import Seo from '../components/Seo';

const ModelHeader = ({ model, subscribeToList }) => {
    const [successMessage, setSuccessMessage] = useState();

    const f = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("This email isn't valid.")
                .required('Please enter an email address.'),
        }),
        onSubmit: async ({ email }, { setErrors, resetForm }) => {
            const rawResponse = await subscribeToList(email);
            console.log(rawResponse);
            const response = await rawResponse.json();

            if (!rawResponse.ok) {
                setErrors({
                    email: response.message,
                });
            } else {
                setSuccessMessage(response.message);
                resetForm();
            }
        },
    });

    return (
        <div className="mx-auto max-w-7xl">
            <div className="flex flex-col items-center gap-5 md:grid md:grid-cols-3">
                <div className="col-span-1">
                    <div className="aspect-w-3 aspect-h-2">
                        <img
                            className="object-cover rounded-lg shadow-lg"
                            src={model.avatar}
                            alt={model.name}
                        />
                    </div>
                </div>
                <div className="col-span-2 space-y-5">
                    <div className="flex flex-wrap items-center gap-5">
                        <p className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                            {model.name}
                        </p>
                        <ul className="flex space-x-5">
                            <li>
                                <a
                                    href={model.store}
                                    className="text-gray-400 hover:text-gray-500"
                                >
                                    <span className="sr-only">FemScat</span>
                                    <svg
                                        className="w-8 h-8"
                                        fill="currentColor"
                                        viewBox="0 0 400 400"
                                        aria-hidden="true"
                                    >
                                        <path d="M93.821 0.636 C 83.723 1.589,76.883 2.989,68.771 5.764 C 32.201 18.273,6.810 49.267,1.153 88.302 L 0.614 92.022 0.532 197.720 C 0.473 274.574,0.524 304.336,0.720 306.779 C 4.720 356.518,44.230 395.623,94.181 399.281 C 100.920 399.775,306.295 399.477,310.018 398.969 C 358.214 392.384,392.384 358.214,398.969 310.018 C 399.477 306.295,399.775 100.920,399.281 94.181 C 395.656 44.684,357.567 5.689,308.098 0.831 C 304.938 0.521,97.048 0.331,93.821 0.636 M209.102 82.733 C 257.337 112.061,292.529 146.433,303.124 174.565 C 309.293 190.947,307.919 208.438,299.289 223.365 C 278.040 260.117,225.912 262.790,201.261 228.391 L 199.940 226.548 198.535 228.511 C 191.541 238.281,181.188 245.883,169.766 249.637 C 123.909 264.706,80.569 221.023,96.355 175.645 C 106.911 145.302,145.054 108.964,199.009 77.848 L 199.938 77.312 202.833 78.997 C 204.425 79.923,207.246 81.605,209.102 82.733 M213.972 276.545 C 221.003 283.935,227.300 290.684,227.965 291.542 C 237.571 303.935,229.148 322.492,213.917 322.492 C 208.972 322.492,202.608 319.591,200.501 316.375 L 200.093 315.752 198.225 317.504 C 189.258 325.912,175.556 323.438,170.001 312.407 C 166.767 305.985,167.414 297.824,171.624 291.960 C 172.074 291.333,177.799 285.169,184.346 278.262 C 190.892 271.356,196.833 265.087,197.547 264.331 C 198.820 262.986,198.869 262.960,200.017 263.033 L 201.189 263.107 213.972 276.545 "></path>
                                    </svg>
                                </a>
                            </li>
                            {model.twitter !== '' && (
                                <li>
                                    <a
                                        href={
                                            'https://twitter.com/' +
                                            model.twitter
                                        }
                                        className="text-gray-400 hover:text-gray-500"
                                    >
                                        <span className="sr-only">Twitter</span>
                                        <svg
                                            className="w-8 h-8"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            aria-hidden="true"
                                        >
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                    </a>
                                </li>
                            )}
                            {model.reddit !== '' && (
                                <li>
                                    <a
                                        href={
                                            'https://reddit.com/u/' +
                                            model.reddit
                                        }
                                        className="text-gray-400 hover:text-gray-500"
                                    >
                                        <span className="sr-only">Reddit</span>

                                        <svg
                                            className="w-8 h-8"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M12 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0zm5.01 4.744c.688 0 1.25.561 1.25 1.249a1.25 1.25 0 0 1-2.498.056l-2.597-.547-.8 3.747c1.824.07 3.48.632 4.674 1.488.308-.309.73-.491 1.207-.491.968 0 1.754.786 1.754 1.754 0 .716-.435 1.333-1.01 1.614a3.111 3.111 0 0 1 .042.52c0 2.694-3.13 4.87-7.004 4.87-3.874 0-7.004-2.176-7.004-4.87 0-.183.015-.366.043-.534A1.748 1.748 0 0 1 4.028 12c0-.968.786-1.754 1.754-1.754.463 0 .898.196 1.207.49 1.207-.883 2.878-1.43 4.744-1.487l.885-4.182a.342.342 0 0 1 .14-.197.35.35 0 0 1 .238-.042l2.906.617a1.214 1.214 0 0 1 1.108-.701zM9.25 12C8.561 12 8 12.562 8 13.25c0 .687.561 1.248 1.25 1.248.687 0 1.248-.561 1.248-1.249 0-.688-.561-1.249-1.249-1.249zm5.5 0c-.687 0-1.248.561-1.248 1.25 0 .687.561 1.248 1.249 1.248.688 0 1.249-.561 1.249-1.249 0-.687-.562-1.249-1.25-1.249zm-5.466 3.99a.327.327 0 0 0-.231.094.33.33 0 0 0 0 .463c.842.842 2.484.913 2.961.913.477 0 2.105-.056 2.961-.913a.361.361 0 0 0 .029-.463.33.33 0 0 0-.464 0c-.547.533-1.684.73-2.512.73-.828 0-1.979-.196-2.512-.73a.326.326 0 0 0-.232-.095z" />
                                        </svg>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                    {model.description.split('\n').map((paragraph, key) => (
                        <p key={key} className="mx-auto text-xl text-gray-500">
                            {paragraph}
                        </p>
                    ))}
                    <form
                        className="grid w-full grid-cols-1 gap-2 mt-8 md:grid-cols-2"
                        onSubmit={f.handleSubmit}
                    >
                        <label htmlFor="email-address" className="sr-only">
                            Email address
                        </label>
                        <input
                            id="email-address"
                            name="email"
                            type="text"
                            autoComplete="email"
                            className="w-full placeholder-gray-500 border-gray-300 rounded-md focus:border-indigo-500 focus:ring-indigo-500"
                            placeholder="Enter your email"
                            value={f.values.email}
                            onChange={f.handleChange}
                        />
                        <div className="w-full rounded-md shadow">
                            <button
                                type="submit"
                                className="flex items-center justify-center w-full px-5 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                {f.isSubmitting ? (
                                    <svg
                                        className="w-6 h-6 text-white animate-spin"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            class="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            stroke-width="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Subscribe for updates'
                                )}
                            </button>
                        </div>
                        {f.touched.email && f.errors.email ? (
                            <p className="text-red-500 sm:text-sm">
                                {f.errors.email}
                            </p>
                        ) : successMessage ? (
                            <p className="text-green-500 sm:text-sm">
                                {successMessage}
                            </p>
                        ) : null}
                    </form>
                </div>
            </div>
        </div>
    );
};

const Model = ({ data }) => {
    const { nodes } = data.allVideosJson;
    const [model] = data.allModelsJson.nodes;

    const subscribeToList = email => {
        return fetch(
            'https://us-central1-ks-www.cloudfunctions.net/subscribeToList',
            {
                method: 'POST',
                body: new URLSearchParams({
                    email,
                    listId: kebabCase(model.name),
                }),
            }
        );
    };

    return (
        <Layout {...data.site.siteMetadata}>
            <Seo
                pageTitle={`${model.name}'s Videos`}
                description={`See all the filthiest, kinkiest scat videos from ${model.name} and all your other favorite scat models, only on KinkyScat.com!`}
                image={model.avatar}
                {...data.site.siteMetadata}
            />
            <ModelHeader model={model} subscribeToList={subscribeToList} />

            <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:max-w-none">
                {nodes.map(video => {
                    return <VideoCard video={video} model={model} />;
                })}
            </div>
        </Layout>
    );
};

export default Model;

export const pageQuery = graphql`
    query ModelQuery($storeId: String) {
        site {
            siteMetadata {
                title
                mainSiteUrl
                social {
                    twitter
                    reddit
                    rss
                }
            }
        }
        allVideosJson(filter: { storeId: { in: [$storeId] } }) {
            totalCount
            nodes {
                storeId
                videoId
                title
                description
                preview
                categories {
                    categoryId
                    categoryName
                }
                link
                date
            }
        }
        allModelsJson(filter: { storeId: { in: [$storeId] } }) {
            nodes {
                storeId
                name
                description
                twitter
                reddit
                store
                avatar
            }
        }
    }
`;
